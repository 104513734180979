import HalfRingImage from "@app-assets/images/product/half-ring.png";
import TheRingPannelImage from "@app-assets/images/product/ring-panel.png";
import LockSvg from "@app-assets/svg/solid/lock.svg";
import TheRingSvg from "@app-assets/svg/the-ring.svg";

import { ProductDetailType } from "./DetailCard";
import ProductInfo from "./ProductInfo";

export default function ProductHeader() {
  return (
    <div className="header-bg">
      <div className="top">
        <div className="half-ring container">
          <img src={HalfRingImage} alt="" />
        </div>
        <div className="lock">
          <div className="m-auto ">
            <img src={LockSvg} alt="" />
          </div>
        </div>
        <div className="the-ring">
          <img src={TheRingSvg} alt="" />
        </div>
      </div>
      <div className="pannel">
        <img src={TheRingPannelImage} alt="" />
        <div className="content">
          {Object.values(ProductDetailType).map((key: any) => (
            <ProductInfo type={key} key={key} />
          ))}
        </div>
      </div>
    </div>
  );
}
