import BannerFooterImage from "@app-assets/images/homepage/banner-footer.png";
import Header from "@app-components/header/Header";
import Banner from "@app-components/landing-page-v2/Banner";
import DoItAllBanner from "@app-components/landing-page-v2/DoItAll";
import IntroFunctions from "@app-components/landing-page-v2/IntroFunctions";
import IntroImages from "@app-components/landing-page-v2/IntroImages";
import LifeStyles from "@app-components/landing-page-v2/LifeStyles";
// import Partners from "@app-components/landing-page-v2/Partners";
import SliderImages from "@app-components/landing-page-v2/SliderImages";
import LPFooter from "@app-components/landing-page/Footer";
import useScrollToTop from "@app-hook/useScrollToTop";

export default function LandingPageV2() {
  useScrollToTop();
  return (
    <div className="landing-page-v2">
      <Header />
      <Banner />
      <div className="page-bg">
        <DoItAllBanner />
        <IntroFunctions />
        <IntroImages />
        <LifeStyles />
        <SliderImages />
        <div className="py-[56px] ">
          <img src={BannerFooterImage} className="m-auto" alt="footer" />
        </div>
        {/* <Partners /> */}
      </div>

      <LPFooter />
    </div>
  );
}
