import { useTranslation } from "react-i18next";

export default function DoItAllBanner() {
  const { t } = useTranslation();

  return (
    <div className="do-it-all-banner flex flex-col md:flex-row">
      <div className="img-container">
        <div className="image"></div>
      </div>
      <div className="container flex">
        <div className="flex-1 placeholder"></div>
        <div className="flex flex-1 content">
          <div className="title">
            <div className="title-secondary">
              {t("page.newHome.headline.doItAll")}
            </div>
            <div className="title-main">
              {t("page.newHome.headline.mainTitle")}
            </div>
          </div>
          <div className="mt-text-body-xxl">
            {t("page.newHome.headline.content")}
          </div>
        </div>
      </div>
    </div>
  );
}
