import { useTranslation } from "react-i18next";

import LifestyleTitleImage from "@app-assets/images/homepage/lifestyles.png";
import LifestyleBgImage from "@app-assets/images/homepage/styles.png";

export default function LifeStyles() {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="lifestyle-images ">
        <div className="flex-1 flex flex-col gap-[36px]">
          <div>
            <img
              src={LifestyleTitleImage}
              alt="lifestyle title"
              className="max-w-lg"
            />
          </div>
          <div className="mt-text-body-xl whitespace-break-spaces">
            {t("page.newHome.style.content")}
          </div>
        </div>
        <div className="flex-1">
          <div>
            <img src={LifestyleBgImage} alt="lifestyle" />
          </div>
        </div>
      </div>
    </div>
  );
}
