import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PATHS } from "@app-utils/routes";

import StyledButton from "../button/StyledButton";

export default function Banner() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="banner-header">
      <div className="flex container flex-row content">
        <div className="flex flex-col gap-[16px] flex-1 justify-center">
          <div className="banner-title">The Ring</div>
          <div className="banner-subtitle">{t("page.newHome.banner")}</div>
          <div>
            <StyledButton
              className="primary mt-button-lg px-[16px]"
              onClick={() => navigate(PATHS.product)}
            >
              {t("page.newHome.startYourJourney")}
            </StyledButton>
          </div>
        </div>
        <div className="lg:flex-1 "></div>
      </div>
    </div>
  );
}
