import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/effect-coverflow";

import MeetTextImage from "@app-assets/images/about/ meet-text.png";
import LeadersImage from "@app-assets/images/about/heads.png";

export default function MidBanner() {
  const { t } = useTranslation();
  return (
    <div className="container mid-banner">
      <div className="flex justify-center">
        <img className="img-text" src={MeetTextImage} alt="" />
      </div>
      <div className="img-container">
        <img className="img" src={LeadersImage} alt="" />
        <div className="content-banner">
          <div className="text">
            <div className="mt-text-body-xxl text-white text-center m-auto max-w-[80vw]">
              {t("page.newAbout.bannerContent")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
