import { useTranslation } from "react-i18next";

import OpenAppSvg from "@app-assets/images/userGuide/open-app.svg";
import RingSizeGuideSvg from "@app-assets/images/userGuide/ring-size-guide.svg";
import WithRingTabSvg from "@app-assets/images/userGuide/with-ring-tab.svg";
import WithoutRingTabSvg from "@app-assets/images/userGuide/without-ring-tab.svg";
import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import GuideImage from "@app-components/userGuide/GuideImage";
import useScrollToTop from "@app-hook/useScrollToTop";
import { getRoutes } from "@app-utils/routes";

export default function GetRingSizePage() {
  const { t } = useTranslation();
  const routes = getRoutes(t);

  useScrollToTop();

  const steps = [
    {
      title: t("page.guide.step", [1]),
      src: OpenAppSvg,
      description: t("page.guide.download"),
    },
    {
      title: t("page.guide.step", [2]),
      src: RingSizeGuideSvg,
      description: t("page.guide.tabRingSizeGuide"),
    },
    {
      title: t("page.guide.step", [3]),
      src: WithRingTabSvg,
      description: t("page.guide.selectWithRingTab"),
    },
    {
      title: t("page.guide.step", [4]),
      src: WithoutRingTabSvg,
      description: t("page.guide.selectWithoutRingTab"),
    },
  ];

  return (
    <div className="container mt-ugd faq faq-detail">
      <MTBreadcrumb items={[routes.home, routes.getRingSize]} />
      <div className="mt-ugd">
        <div className="mt-title">
          <span>{t("page.product.guide.howToGetYourRingSize")}</span>
        </div>

        <div className="mt-ugd__grs-imgs">
          {steps.map((step) => {
            return (
              <GuideImage
                key={step.title}
                className="ur-img"
                title={step.title}
                src={step.src}
                description={step.description}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
