import { Modal } from "antd";
import classNames from "classnames";
import { useState } from "react";

import { SolidIcon } from "@app-components/svg/icon";

export default function FunctionDetail({
  image,
  title,
  content,
  showTitle = true,
}: {
  image: string;
  content: string;
  title?: string;
  showTitle?: boolean;
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="function-detail" onClick={() => setOpen(true)}>
        <img src={image} className="image" alt="" />
        <div className={classNames("content", { inner: showTitle })}>
          {showTitle && <div className="title">{title}</div>}
          <div
            className={classNames("icon", { inner: showTitle })}
            onClick={() => setOpen(true)}
          >
            <SolidIcon.PlusCircle />
          </div>
        </div>
      </div>
      {open && (
        <Modal
          centered
          open={open}
          footer={null}
          onCancel={() => setOpen(false)}
          bodyStyle={{
            width: "auto",
          }}
          style={{ width: "auto" }}
          className="modal-w-auto"
        >
          <div className="max-w-[420px] py-[24px]">
            {title && <div className="mt-title-xxl mb-[8px]">{title}</div>}
            <div className="mt-text-body-lg">{content}</div>
          </div>
        </Modal>
      )}
    </>
  );
}
