import { useState } from "react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import {
  Autoplay,
  EffectCoverflow,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import Member1 from "@app-assets/images/about/team1.png";
import Member2 from "@app-assets/images/about/team2.png";
import Member3 from "@app-assets/images/about/team3.png";
import Member4 from "@app-assets/images/about/team4.png";
import Member5 from "@app-assets/images/about/team5.png";

const SPEED = 800;
const images = [Member5, Member1, Member2, Member3, Member4];

export default function SliderMember() {
  const [swiper, setSwiper] = useState<SwiperClass>();

  const handleOnChangeSlide = (slide: SwiperClass) => {
    if (slide.activeIndex > slide.clickedIndex) {
      swiper?.slidePrev(SPEED);
    } else if (slide.activeIndex < slide.clickedIndex) {
      swiper?.slideNext(SPEED);
    }
  };
  return (
    <Swiper
      onSwiper={setSwiper}
      onClick={handleOnChangeSlide}
      onInit={(e: any) => {
        e?.slideNext(SPEED);
      }}
      effect={"coverflow"}
      grabCursor
      centeredSlides
      slidesPerView={"auto"}
      coverflowEffect={{
        rotate: 80,
        depth: -100,
        modifier: 0.2,
        scale: 1.2,
      }}
      className="mySwiper"
      keyboard
      loopAddBlankSlides
      loop={true}
      modules={[
        Navigation,
        Autoplay,
        EffectCoverflow,
        Pagination,
        Keyboard,
        Mousewheel,
      ]}
      spaceBetween={10}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      speed={1000}
      pagination={{
        clickable: true,
        type: undefined,
      }}
    >
      {images.map((item, index) => (
        <SwiperSlide key={index}>
          <div className="overlay"></div>
          <img src={item} alt="" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
