import { useTranslation } from "react-i18next";

import RingFunc2 from "@app-assets/images/ring-func-2.png";
import RingFunc3 from "@app-assets/images/ring-func-3.png";
import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import RingOverview from "@app-components/ringFunction/RingOverview";
import GuideImage from "@app-components/userGuide/GuideImage";
import useScrollToTop from "@app-hook/useScrollToTop";
import { getRoutes } from "@app-utils/routes";

export default function UsingRingPage() {
  const { t } = useTranslation();
  const routes = getRoutes(t);
  useScrollToTop();

  return (
    <div className="container mt-ugd faq faq-detail">
      <MTBreadcrumb items={[routes.home, routes.useRing]} />
      <div className="mt-ugd">
        <div className="mt-title">
          <span>{t("page.product.guide.howToUseTheRing")}</span>
        </div>
        <div className="mt-ugd__container">
          <div className="mt-ugd__ur">
            <div className="mt-ugd__ur-img">
              <div className="lp-mansory__image">
                <RingOverview />
              </div>
            </div>
            <div className="mt-ugd__ur-content">
              <div className="mt-ugd__title">
                {t("page.product.guide.theRingDetail.title")}
              </div>
              <div
                className="mt-ugd__description"
                dangerouslySetInnerHTML={{
                  __html: t("page.product.guide.theRingDetail.content"),
                }}
              ></div>
            </div>
          </div>
          <div className="mt-ugd__ur-imgs">
            <GuideImage
              className="ur-img"
              title={t("page.product.guide.theRingDetail.forAccessKey")}
              src={RingFunc2}
              // description={t("page.guide.forAccessKeyDesc")}
            />
            <GuideImage
              className="ur-img"
              title={t("page.product.guide.theRingDetail.forPayment")}
              src={RingFunc3}
              // description={t("page.guide.forPaymentDesc")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
