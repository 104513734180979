import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { useNavigate } from "react-router-dom";

import AddBankImage from "@app-assets/svg/guides/add-bank.svg";
import AccessKeyImage from "@app-assets/svg/guides/add-building.svg";
import RingSizeImage from "@app-assets/svg/guides/ring-size.svg";
import UseRingImage from "@app-assets/svg/guides/use-ring.svg";
import { SolidIcon } from "@app-components/svg/icon";
import { PATHS } from "@app-utils/routes";

import "./styles.scss";

const guides = [
  {
    title: "page.newProduct.guide.useRing",
    image: UseRingImage,
    link: PATHS.useRing,
  },
  {
    title: "page.newProduct.guide.ringSize",
    image: RingSizeImage,
    link: PATHS.getRingSize,
  },
  {
    title: "page.newProduct.guide.addBank",
    image: AddBankImage,
    link: PATHS.forPayment,
  },
  {
    title: "page.newProduct.guide.accessKey",
    image: AccessKeyImage,
    link: PATHS.accessControl,
  },
];

export default function ProductGuides() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="guides-bg">
      <div className="guides-container">
        <ScrollContainer className="scroll-wrapper">
          {guides.map((guide, index) => (
            <div
              className="content"
              key={index}
              onClick={() => navigate(guide.link)}
            >
              <div className="image">
                <img src={guide.image} alt="" />
              </div>
              <div className="flex flex-1 flex-col items-center">
                <div className="title ">{t(guide.title)}</div>
                <div className="icon">
                  <SolidIcon.PlusCircle color="#fcfbfc" />
                </div>
              </div>
            </div>
          ))}
        </ScrollContainer>
      </div>
    </div>
  );
}
