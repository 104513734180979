import ScrollContainer from "react-indiana-drag-scroll";

import MemberInfo from "./MemberInfo";

const MEMBERS = [
  {
    name: "Paul Emile Destouches (Polo)",
    role: "page.pns.team.ceo.title",
    image: require("@app-assets/images/people/polo.jpg"),
    desc: "page.pns.team.ceo.desc",
  },
  {
    name: "Tran Chi Cao (Cika)",
    role: "page.aboutUs.section4.role.headOfProduct",
    image: require("@app-assets/images/people/cika.jpg"),
    desc: "page.aboutUs.section4.desc.cika",
  },
  {
    name: "Copp",
    role: "page.aboutUs.section4.role.headOfPartnership",
    image: require("@app-assets/images/people/copp.jpg"),
    desc: "page.aboutUs.section4.desc.copp",
  },
];

const MEMBERS1 = [
  {
    name: "Nguyen Ha Nam",
    role: "page.aboutUs.section4.role.headOfFinance",
    image: require("@app-assets/images/people/nam.jpg"),
    desc: "page.aboutUs.section4.desc.name",
  },
  {
    name: "Do Tuyet Linh",
    role: "page.pns.team.headOfPnsDev.title",
    image: require("@app-assets/images/people/linh.jpg"),
    desc: "page.pns.team.headOfPnsDev.desc",
  },
  {
    name: "Thieu Yen",
    role: "page.aboutUs.section4.role.headOfMarketing",
    image: require("@app-assets/images/people/yen.jpg"),
    desc: "page.aboutUs.section4.desc.yen",
  },
  {
    name: "Trieu Hiep Loc",
    role: "page.pns.team.strategyConsultant.title",
    image: require("@app-assets/images/people/trieu.jpg"),
    desc: "page.pns.team.strategyConsultant.decs",
  },
];
export default function MemberInfos() {
  return (
    <div className="member-infos container">
      <ScrollContainer className="list-members">
        {MEMBERS.map((item, index) => (
          <MemberInfo
            key={index}
            image={item.image}
            name={item.name}
            role={item.role}
            desc={item.desc}
          />
        ))}
      </ScrollContainer>
      <ScrollContainer className="list-members">
        {MEMBERS1.map((item, index) => (
          <MemberInfo
            key={index}
            image={item.image}
            name={item.name}
            role={item.role}
            desc={item.desc}
          />
        ))}
      </ScrollContainer>
    </div>
  );
}
