import { Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function MemberInfo({
  image,
  name,
  role,
  desc,
}: {
  image: string;
  name: string;
  role: string;
  desc: string;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className="member" onClick={() => setOpen(true)}>
        <div className="img">
          <img src={image} alt="" />
          <div className="overlay"></div>
        </div>
        <div>
          <div className="name">{name}</div>
          <div className="role">{t(role)}</div>
        </div>
      </div>
      {open && (
        <Modal
          open={open}
          centered
          footer={null}
          onCancel={() => setOpen(false)}
          bodyStyle={{
            maxWidth: "1000px",
            padding: 20,
          }}
          style={{ width: "auto", padding: 20 }}
          className="modal-w-auto"
        >
          <div className="flex flex-col items-center md:flex-row gap-[32px] info-modal">
            <img className="flex-1" src={image} alt="" />
            <div className="flex flex-col flex-1">
              <div className="mt-title-xl">{name}</div>
              <div className="mt-text-body-lg role">{t(role)}</div>
              <div className="mt-text-body-lg">{t(desc)}</div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
