import ChargelessImage from "@app-assets/images/homepage/no-charge.png";
import SecureImage from "@app-assets/images/homepage/secure.png";
import StrongImage from "@app-assets/images/homepage/strong.png";
import UnwetImage from "@app-assets/images/homepage/water.png";

export default function IntroImages() {
  return (
    <div className="intro-images container gap-[24px]">
      <div className="flex-1 img-container">
        <img src={SecureImage} alt="intro" />
      </div>
      <div className="flex flex-col flex-wrap md:flex-nowrap md:flex-row gap-[24px]">
        <div className="img-container flex-1">
          <img src={UnwetImage} alt="intro 1" />
        </div>
        <div className="img-container flex-1">
          <img src={ChargelessImage} alt="intro 2" />
        </div>
        <div className="img-container flex-1">
          <img src={StrongImage} alt="intro 3" />
        </div>
      </div>
    </div>
  );
}
