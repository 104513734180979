import classNames from "classnames";
import { useState } from "react";

import SliderImage2 from "@app-assets/images/homepage/slider2.png";
import SliderImage1 from "@app-assets/images/homepage/slider-1.png";
import SliderImage3 from "@app-assets/images/homepage/slider-3.png";

export default function SliderImages() {
  const [activeIndex, setActiveIndex] = useState(0);
  const imgs = [SliderImage1, SliderImage2, SliderImage3];
  return (
    <div className="slider-images container gap-[24px] ">
      <div className="flex imgs">
        {imgs.map((itm, index) => (
          <div
            onClick={() => setActiveIndex(index)}
            key={index}
            className={classNames("flex-1 slider", {
              active: activeIndex === index,
              mid:
                (activeIndex < index && index - activeIndex === 1) ||
                (activeIndex > index && activeIndex - index === 1),
            })}
          >
            <div
              className={classNames("bg", { overlay: activeIndex !== index })}
            />
            <img src={itm} alt="slider" />
          </div>
        ))}
      </div>
    </div>
  );
}
