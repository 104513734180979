import { PlusCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import MTRing from "@app-assets/images/mt-ring.png";
import MTRingFront from "@app-assets/images/ring-front.png";
import MTRingUp from "@app-assets/images/ring-up.png";
import ResilientEleganceIcon from "@app-assets/svg/product/reselient.svg";
import SeamlessConnectivityIcon from "@app-assets/svg/product/seamless.svg";
import SpecificationsIcon from "@app-assets/svg/product/specification.svg";
import UltimateComfortIcon from "@app-assets/svg/product/ultimate.svg";

import "./styles.scss";

export enum ProductDetailType {
  ResilientElegance = "ResilientElegance",
  UltimateComfort = "UltimateComfort",
  SeamlessConnectivity = "SeamlessConnectivity",
  Specifications = "Specifications",
}

const productDetailTypeContent: any = {
  [ProductDetailType.ResilientElegance]: {
    icon: ResilientEleganceIcon,
    image: MTRingFront,
    title: "page.product.resilientElegance.title",
    subtitle: "page.product.resilientElegance.subtitle",
    content: "page.product.resilientElegance.content",
  },
  [ProductDetailType.UltimateComfort]: {
    icon: UltimateComfortIcon,
    image: MTRing,
    title: "page.product.ultimateComfort.title",
    subtitle: "page.product.ultimateComfort.subtitle",
    content: "page.product.ultimateComfort.content",
  },
  [ProductDetailType.SeamlessConnectivity]: {
    icon: SeamlessConnectivityIcon,
    image: MTRingUp,
    title: "page.product.seamlessConnectivity.title",
    subtitle: "page.product.seamlessConnectivity.subtitle",
    content: "page.product.seamlessConnectivity.content",
  },
  [ProductDetailType.Specifications]: {
    icon: SpecificationsIcon,
    title: "page.product.specifications.title",
    subtitle: "page.product.specifications.subtitle",
    content: "page.product.specifications.content",
  },
};

export default function ProductInfo({ type }: { type: ProductDetailType }) {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const value = productDetailTypeContent[type];

  const contentModal = useMemo(() => {
    return (
      <div className="product-detail-card-modal">
        {value?.image && (
          <div className="product-detail-card_img">
            <img src={value.image} alt="" />
          </div>
        )}
        <div className="mt-heading-xl">{t(value.title)}</div>
        <div className="mt-text-body-xl">{t(value.content)}</div>
        {value.subcontent && (
          <div className="product-detail-card__subcontent mt-text-body-lg">
            {value.subcontent.map((items: string[], index: number) => (
              <ul className="product-detail-card__subcontent-item" key={index}>
                {items.map((item: string) => (
                  <li key={item}>{t(item)}</li>
                ))}
              </ul>
            ))}
          </div>
        )}
      </div>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, type]);

  return (
    <>
      <div className="info-row" onClick={() => setToggle(true)}>
        <img src={value.icon} alt="" />
        <div className="mt-heading-md title flex-1">{t(value.title)}</div>
        <div className="plus-icon text-white text-[24px]">
          <PlusCircleFilled />
        </div>
      </div>
      {
        <Modal
          centered
          open={toggle}
          footer={null}
          onCancel={() => setToggle(false)}
          bodyStyle={{
            maxWidth: "1000px",
            padding: 20,
          }}
          style={{ width: "auto", padding: 20 }}
          className="modal-w-auto"
        >
          {contentModal}
        </Modal>
      }
    </>
  );
}
